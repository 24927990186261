<template>
  <v-card>
    <div>
      <v-layout wrap>
        <v-flex xs12 sm12 md12 lg2 xl2 grow pa-1 offset-lg-5 offset-xl-5>
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="เดือน"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" type="month" locale="th" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.dialog.save(date); monthChanged();">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 xl12 grow pa-1>
          <v-chart
            class="chart"
            :loading="dataLoading"
            :autoresize="true"
            :option="option"
          />
        </v-flex>
      </v-layout>
    </div>
  </v-card>
</template>

<script>
import axios from "axios";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
]);

export default {
  name: "ICTSolutionIncomeBySectorStackedChart",
  props: {
    officeSectorID: {
      type: Array,
      required: true,
    },
  },
  components: { VChart },
  data: () => ({
    dataLoading: false,
    loaded: false,
    interval: null,
    hasSeries: false,
    series: null,
    modal: false,
    date: new Date().toISOString().substr(0, 7),
    option: {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        show: true,
        top: "bottom",
      },
      grid: {
        left: "5%",
        right: "5%",
        bottom: "20%",
      },
      title: {
        left: "center",
        text: "",
      },
      colorBy: "series",
      toolbox: {
        right: 30,
        feature: {
          saveAsImage: {
            pixelRatio: 3,
          },
        },
      },
      xAxis: {
        type: "category",
        axisLabel: {
          rotate: 45,
        },
      },
      yAxis: {
        type: "value",
      },
      series: [],
    },
  }),
  mounted() {
    this.loaded = false;
    this.dataLoading = false;
    this.hasSeries = false;
    this.getDataFromAPI();

    // this.interval = setInterval(() => {
    //   this.getDataFromAPI();
    // }, 60000);
  },
  beforeDestroy() {
    // clearInterval(this.interval);
  },
  methods: {
    getDataFromAPI() {
      this.dataLoading = true;
      const queryParams = {
        params: {
          officeSectorID: this.officeSectorID,
          month: this.date
        },
      };
      axios
        .get("/dashboard/ict-solution-income-by-sector-stacked-bar-chart", queryParams)
        .then((response) => {
          const result = response.data;
          this.option.xAxis.data = result.categories;
          this.option.title.text = `รายได้ ICT Solution`;
          result.series = result.series.map((serie) => {
            serie.type = "bar";
            serie.stack = "total";
            // serie.label = {show: true};
            serie.emphasis = { focus: "series" };
            serie.barWidth = "85%";
            return serie;
          });
          // console.log(result.series)
          this.option.series = result.series;
          this.hasSeries = result.series.length > 0;
          this.loaded = true;
          this.dataLoading = false;
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },
    monthChanged() {
      // console.log(this.date)
      this.loaded = false;
      this.hasSeries = false;
      this.getDataFromAPI();
    }
  },
  // watch: {
  //   date(){
  //     console.log(this.date)
  //     this.loaded = false;
  //     this.hasSeries = false;
  //     this.getDataFromAPI();
  //   }
  // },
};
</script>

<style scoped>
.chart {
  height: 650px;
}
</style>
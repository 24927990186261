var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","xl12":"","grow":"","pa-1":""}},[_c('CustomerMeetingBySectorChart',{attrs:{"officeSectorID":[
          '49',
          '50',
          '51',
          '52',
          '53',
          '54',
          '55',
          '56',
          '57',
          '59',
          '62',
          '64',
          '65',
        ]}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
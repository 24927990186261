<template>
    <v-container>
      <v-layout wrap>
        <v-flex xs12 sm12 md12 lg12 xl12 grow pa-1>
          <ICTSolutionCustomerBySectorChart
          :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>

        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น[1]'"
            :customerCategory="1"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>
  
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerPercentBySectorStackedChart
            :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น'"
            :customerCategory="1"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>

        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้าสาธารณสุข กลุ่มโรงพยาบาลรัฐ[9]'"
            :customerCategory="9"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>

        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้าสถานศึกษา (สพฐ.)[2]'"
            :customerCategory="2"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้าสถานศึกษา สกร.(กศน. เดิม)[3]'"
            :customerCategory="3"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>
  <!-- 
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerPercentBySectorStackedChart
          :chartTitle="'เปอร์เซ็นต์ลูกค้าสาธารณสุข กลุ่มโรงพยาบาลรัฐ[9]'"
          :customerCategory="9"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex> -->

   <!--    <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่มสถานศึกษา'"
            :customerCategory="[
              '2',
              '3',
              '4',
              '5',
              '6',
              '7',
              '8',
            ]"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>
  
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerPercentBySectorStackedChart
          :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่มสถานศึกษา'"
          :customerCategory="[
              '2',
              '3',
              '4',
              '5',
              '6',
              '7',
              '8',
            ]"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>--> 
<!--สพฐ
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่มสถานศึกษา (สพฐ.)'"
            :customerCategory="2"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>
          <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerPercentBySectorStackedChart
          :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่มสถานศึกษา  (สพฐ.)'"
            :customerCategory="2"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>
  -->
<!--กศน
<v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่มสถานศึกษาสกร.(เดิม กศน.)'"
            :customerCategory="3"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>
          <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerPercentBySectorStackedChart
          :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่มสถานศึกษาสกร.(เดิม กศน.)'"
            :customerCategory="3"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>
        -->
<!--สถานศึกษาเอกชน
<v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่มสถานศึกษาเอกชน'"
            :customerCategory="4"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>
          <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerPercentBySectorStackedChart
          :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่มสถานศึกษาเอกชน'"
            :customerCategory="4"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>
-->
<!--กรมส่งเสริมการปกครองท้องถิ่น
<v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่มสถานศึกษาสังกัดกรมส่งเสริมการปกครองท้องถิ่น'"
            :customerCategory="5"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>
          <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerPercentBySectorStackedChart
          :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่มสถานศึกษาสังกัดกรมส่งเสริมการปกครองท้องถิ่น'"
            :customerCategory="5"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>
-->
<!--สถานศึกษา (อาชีวศึกษา)
<v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่มสถานศึกษา (อาชีวศึกษา)'"
            :customerCategory="6"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>
          <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerPercentBySectorStackedChart
          :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่มสถานศึกษา (อาชีวศึกษา)'"
            :customerCategory="6"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>
-->
<!--สถานศึกษา (สำนักพุทธ)
<v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่มสถานศึกษา (สำนักพุทธ)'"
            :customerCategory="7"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>
          <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerPercentBySectorStackedChart
          :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่มสถานศึกษา (สำนักพุทธ)'"
            :customerCategory="7"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>
-->

<!--สถานศึกษา (อุดมศึกษา)
<v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่มสถานศึกษา (อุดมศึกษา)'"
            :customerCategory="8"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>
          <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerPercentBySectorStackedChart
          :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่มสถานศึกษา (อุดมศึกษา)'"
            :customerCategory="8"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>
-->
        <!--ส่วนงานสาธารณสุข (รพ. เอกชน)
<v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่มส่วนงานสาธารณสุข (รพ. เอกชน)'"
            :customerCategory="10"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>
          <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerPercentBySectorStackedChart
          :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่มส่วนงานสาธารณสุข (รพ. เอกชน)'"
            :customerCategory="10"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>
-->

            <!--ส่วนงานราชการ
<v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่มส่วนงานราชการ'"
            :customerCategory="11"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>
          <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerPercentBySectorStackedChart
          :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่มส่วนงานราชการ'"
            :customerCategory="11"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>
-->
             <!--อื่นๆ
<v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่มอื่นๆ'"
            :customerCategory="12"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>
          <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerPercentBySectorStackedChart
          :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่มอื่นๆ'"
            :customerCategory="12"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>
-->
      </v-layout>
    </v-container>
  </template>
  
  <script>
  import ICTSolutionCustomerBySectorChart from "./ICTSolutionCustomerBySectorChart.vue";
  import ICTSolutionCustomerPerCustomerBySectorStackedChart from "./ICTSolutionCustomerPerCustomerBySectorStackedChart.vue";
  import ICTSolutionCustomerPerCustomerPercentBySectorStackedChart from "./ICTSolutionCustomerPerCustomerPercentBySectorStackedChart.vue";
  
  
  export default {
    name: "ICTSolutionCustomeDashboard",
    components: {
      ICTSolutionCustomerBySectorChart,
      ICTSolutionCustomerPerCustomerBySectorStackedChart,
      ICTSolutionCustomerPerCustomerPercentBySectorStackedChart
  
    },
    props: {

    },
  };
  </script>
  

  <style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  </style>
<template>
    <v-container>
      <v-layout wrap>

        <v-flex xs12 sm12 md12 lg12 xl12 grow pa-1>
          <CustomerBySectorStackedChart
          :officeSectorID="[
              '50',
              '49',
              '54',
              '56',
              '55',
              '52',
              '62',
              '53',
              '51',
              '64',
              '65',
              '59',
              '57',
            ]"
          />
        </v-flex>

        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <!--graph จำนวนลูกค้า type1-->
          <CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น[1]'"
            :customerCategory="1"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>

        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
            <!--graph percent type1 -->
          <CustomerHasCRMDataOrSpecialBillDataPerCustomerPercentBySectorStackedChart
            :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น[1]'"
            :customerCategory="1"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>
  
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
            <CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้าสาธารณสุข กลุ่มโรงพยาบาลของรัฐ[9]'"
            :customerCategory="9"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>

   <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerHasCRMDataOrSpecialBillDataPerCustomerPercentBySectorStackedChart
          :chartTitle="'เปอร์เซ็นต์ลูกค้าสาธารณสุข กลุ่มโรงพยาบาลของรัฐ[9]'"
            :customerCategory="9"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex> 




        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้า รพ.สต.สังกัด(กรมส่งเสริมการปกครองท้องถิ่น)[15]'"
            :customerCategory="15"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>

        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerHasCRMDataOrSpecialBillDataPerCustomerPercentBySectorStackedChart
          :chartTitle="'เปอร์เซ็นต์ลูกค้า รพ.สต.สังกัด(กรมส่งเสริมการปกครองท้องถิ่น)[15]'"
            :customerCategory="15"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex> 


       <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <!--graph จำนวนลูกค้า  type 2-->
          <CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่มสถานศึกษา (สพฐ.)[2]'"
            :customerCategory="2"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>

      <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          
          <CustomerHasCRMDataOrSpecialBillDataPerCustomerPercentBySectorStackedChart
            :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่มสถานศึกษา (สพฐ.)[2]'"
            :customerCategory="2"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>

        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม ส่วนงานราชการ[11]'"
            :customerCategory="11"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>
        





        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <!--graph จำนวนลูกค้า  type 3-->
          <CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่มสถานศึกษาสกร.(เดิม กศน.)[3]'"
            :customerCategory="3"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>

        <!--<v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
            graph percent type 2
          <CustomerHasCRMDataOrSpecialBillDataPerCustomerPercentBySectorStackedChart
            :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่มสถานศึกษา(กศน.)'"
            :customerCategory="3"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>-->

        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <!--graph จำนวนลูกค้า  type 4-->
          <CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่มสถานศึกษาเอกชน[4]'"
            :customerCategory="4"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>

          <!-- <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          graph percent type 4
          <CustomerHasCRMDataOrSpecialBillDataPerCustomerPercentBySectorStackedChart
            :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่มสถานศึกษาเอกชน'"
            :customerCategory="4"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>-->

        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <!--graph จำนวนลูกค้า  type 5-->
          <CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่มสถานศึกษา (กรมส่งเสริมการปกครองท้องถิ่น)[5]'"
            :customerCategory="5"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>
     <!--   <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
            graph percent type 5
          <CustomerHasCRMDataOrSpecialBillDataPerCustomerPercentBySectorStackedChart
            :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่มสถานศึกษา (กรมส่งเสริมการปกครองท้องถิ่น)'"
            :customerCategory="5"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>-->

        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <!--graph จำนวนลูกค้า  type 6-->
          <CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่มสถานศึกษาอาชีวศึกษา[6]'"
            :customerCategory="6"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>
   <!--     <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
            graph percent type 6
          <CustomerHasCRMDataOrSpecialBillDataPerCustomerPercentBySectorStackedChart
            :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่มสถานศึกษาอาชีวศึกษา'"
            :customerCategory="6"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>-->

        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <!--graph จำนวนลูกค้า  type 7-->
          <CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่มสถานศึกษาสังกัดสำนักพุทธ[7]'"
            :customerCategory="7"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>
     <!--   <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
            graph percent type 7
          <CustomerHasCRMDataOrSpecialBillDataPerCustomerPercentBySectorStackedChart
            :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่มสถานศึกษาสังกัดสำนักงานพระพุทธศาสนาแห่งชาติ'"
            :customerCategory="7"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>-->

        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <!--graph จำนวนลูกค้า  type 8-->
          <CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่มสถานศึกษา (อุดมศึกษา)[8]'"
            :customerCategory="8"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>

       <!--  <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
           graph percent type 8
          <CustomerHasCRMDataOrSpecialBillDataPerCustomerPercentBySectorStackedChart
            :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่มสถานศึกษาอาชีวศึกษา'"
            :customerCategory="8"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>-->

        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม ส่วนงานสาธารณสุข (รพ. เอกชน)[10]'"
            :customerCategory="10"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>
        

        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้าเอกชน(ที่ไม่ใช่กลุ่มสาธารณสุขและสถานศึกษา)[14]'"
            :customerCategory="14"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>

    

        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม อื่นๆ[12]'"
            :customerCategory="12"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>
  <!--
  
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น ที่ใช้บริการ ICT Solution'"
            :customerCategory="1"
            :serviceCategory="[
              '22',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerPercentBySectorStackedChart
            :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น ที่ใช้บริการ ICT Solution'"
            :customerCategory="1"
            :serviceCategory="[
              '22',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม สถานศึกษา ที่ใช้บริการ ICT Solution'"
            :customerCategory="2"
            :serviceCategory="[
              '22',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerPercentBySectorStackedChart
            :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่ม สถานศึกษา ที่ใช้บริการ ICT Solution'"
            :customerCategory="2"
            :serviceCategory="[
              '22',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น ที่ใช้บริการ Smart Network'"
            :customerCategory="1"
            :serviceCategory="[
              '2',
              '3',
              '4',
              '5',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerPercentBySectorStackedChart
            :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น ที่ใช้บริการ Smart Network'"
            :customerCategory="1"
            :serviceCategory="[
              '2',
              '3',
              '4',
              '5',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม สถานศึกษา ที่ใช้บริการ Smart Network'"
            :customerCategory="2"
            :serviceCategory="[
              '2',
              '3',
              '4',
              '5',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerPercentBySectorStackedChart
            :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่ม สถานศึกษา ที่ใช้บริการ Smart Network'"
            :customerCategory="2"
            :serviceCategory="[
              '2',
              '3',
              '4',
              '5',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น ที่ใช้บริการ Smart Voice Solution ภก.1'"
            :customerCategory="1"
            :serviceCategory="[
              '20',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerPercentBySectorStackedChart
            :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น ที่ใช้บริการ Smart Voice Solution ภก.1'"
            :customerCategory="1"
            :serviceCategory="[
              '20',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม สถานศึกษา ที่ใช้บริการ Smart Voice Solution ภก.1'"
            :customerCategory="2"
            :serviceCategory="[
              '20',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerPercentBySectorStackedChart
            :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่ม สถานศึกษา ที่ใช้บริการ Smart Voice Solution ภก.1'"
            :customerCategory="2"
            :serviceCategory="[
              '20',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น ที่ใช้บริการ Complex Network'"
            :customerCategory="1"
            :serviceCategory="[
              '17',
              '18',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerPercentBySectorStackedChart
            :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น ที่ใช้บริการ Complex Network'"
            :customerCategory="1"
            :serviceCategory="[
              '17',
              '18'
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม สถานศึกษา ที่ใช้บริการ Complex Network'"
            :customerCategory="2"
            :serviceCategory="[
              '17',
              '18',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerPercentBySectorStackedChart
            :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่ม สถานศึกษา ที่ใช้บริการ Complex Network'"
            :customerCategory="2"
            :serviceCategory="[
              '17',
              '18'
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น ที่ใช้บริการ MA'"
            :customerCategory="1"
            :serviceCategory="[
              '21',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerPercentBySectorStackedChart
            :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น ที่ใช้บริการ MA'"
            :customerCategory="1"
            :serviceCategory="[
              '21',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม สถานศึกษา ที่ใช้บริการ MA'"
            :customerCategory="2"
            :serviceCategory="[
              '21',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerPercentBySectorStackedChart
            :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่ม สถานศึกษา ที่ใช้บริการ MA'"
            :customerCategory="2"
            :serviceCategory="[
              '21',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
      -->
   
      </v-layout>
    </v-container>
  </template>
  
  <script>
  import CustomerBySectorStackedChart from "./CustomerBySectorStackedChart.vue";
  // import CustomerUseServicePerCustomerBySectorStackedChart from "./Dashboard/CustomerUseServicePerCustomerBySectorStackedChart.vue";
  // import CustomerUseServicePerCustomerPercentBySectorStackedChart from "./Dashboard/CustomerUseServicePerCustomerPercentBySectorStackedChart.vue";
  import CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart from "./CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart.vue";
  import CustomerHasCRMDataOrSpecialBillDataPerCustomerPercentBySectorStackedChart from "./CustomerHasCRMDataOrSpecialBillDataPerCustomerPercentBySectorStackedChart.vue";
  
  
  export default {
    name: "CustomerDashboard",
    components: {
      CustomerBySectorStackedChart,
      // CustomerUseServicePerCustomerBySectorStackedChart,
      // CustomerUseServicePerCustomerPercentBySectorStackedChart,
      CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart,
      CustomerHasCRMDataOrSpecialBillDataPerCustomerPercentBySectorStackedChart
  
    },
    props: {
      // msg: String,
    },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  </style>
<template>
  <v-card>
    <div>
      <v-layout wrap>
        <v-flex xs12 sm12 md12 lg2 xl2 grow pa-1 offset-lg-5 offset-xl-5>
          <span class="text-h5">
            <v-btn
              class="mx-1"
              fab
              plain
              small
              text
              color="black"
              @click="decreaseYear()"
            >
              <v-icon dark> mdi-chevron-left </v-icon>
            </v-btn>
            ปี {{ this.year + 543 - 0 }}
            <v-btn
              class="mx-1"
              fab
              plain
              small
              text
              color="black"
              @click="increaseYear()"
            >
              <v-icon dark> mdi-chevron-right </v-icon>
            </v-btn>
          </span>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 xl12 grow pa-1>
          <v-chart
            class="chart"
            :autoresize="true"
            :option="option"
            :loading="dataLoading"
          />
        </v-flex>
      </v-layout>
    </div>
  </v-card>
</template>
  
  <script>
// import VueApexCharts from "vue-apexcharts";
import axios from "axios";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
]);

export default {
  name: "MonthlyICTSolutionIncomeBySectorLineChart",
  props: {
    officeSectorID: {
      type: Array,
      required: true,
    },
  },
  // components: { VueApexCharts },
  components: { VChart },
  data: () => ({
    dataLoading: false,
    loaded: false,
    year: new Date().getFullYear(),
    interval: null,
    hasSeries: false,
    option: {
      tooltip: {
        trigger: "axis",
        order: "valueDesc",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        top: "bottom",
      },
      grid: {
        left: "10%",
        right: "10%",
        bottom: "20%",
      },
      title: {
        left: "center",
        text: "",
      },
      colorBy: "series",
      toolbox: {
        feature: {
          saveAsImage: {
            pixelRatio: 3,
          },
        },
      },
      xAxis: {
        type: "category",
        axisLabel: {
          rotate: 45,
        },
        data: [
          "ม.ค.",
          "ก.พ.",
          "มี.ค.",
          "เม.ย.",
          "พ.ค.",
          "มิ.ย.",
          "ก.ค.",
          "ส.ค.",
          "ก.ย.",
          "ต.ค.",
          "พ.ย.",
          "ธ.ค.",
        ],
      },
      yAxis: {
        type: "value",
      },
      series: [],
    },
  }),
  mounted() {
    this.loaded = false;
    this.hasSeries = false;
    this.getDataFromAPI();
  },
  beforeDestroy() {},
  methods: {
    getDataFromAPI() {
      this.dataLoading = true;
      const queryParams = {
        params: {
          year: this.year,
          officeSectorID: this.officeSectorID,
        },
      };
      axios
        .get(
          "/dashboard/monthly-ict-solution-income-by-sector-line-chart",
          queryParams
        )
        .then((response) => {
          let result = response.data;

          result = result.map((serie) => {
            serie.type = "line";
            return serie;
          });
          this.option.series = result;
          this.option.title.text = `รายได้ ICT Solution รายส่วนงาน ปี ${
            this.year + 543
          }`;
          this.hasSeries = result.length > 0;
          this.dataLoading = false;
          this.loaded = true;
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },
    decreaseYear() {
      this.year -= 1;
      this.loaded = false;
      this.hasSeries = false;
      this.getDataFromAPI();
    },
    increaseYear() {
      this.year += 1;
      this.loaded = false;
      this.hasSeries = false;
      this.getDataFromAPI();
    },
  },
  // watch: {
  //   service(){
  //     this.loaded = false;
  //     this.hasSeries = false;
  //     this.getDataFromAPI();
  //   }
  // },
};
</script>
  <style scoped>
.chart {
  height: 450px;
}
</style>
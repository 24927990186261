var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","xl12":"","grow":"","pa-1":""}},[_c('ICTSolutionCustomerBySectorChart',{attrs:{"officeSectorID":[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg6":"","xl6":"","grow":"","pa-1":""}},[_c('ICTSolutionCustomerPerCustomerBySectorStackedChart',{attrs:{"chartTitle":'จำนวนลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น[1]',"customerCategory":1,"officeSectorID":[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg6":"","xl6":"","grow":"","pa-1":""}},[_c('ICTSolutionCustomerPerCustomerPercentBySectorStackedChart',{attrs:{"chartTitle":'เปอร์เซ็นต์ลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น',"customerCategory":1,"officeSectorID":[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg6":"","xl6":"","grow":"","pa-1":""}},[_c('ICTSolutionCustomerPerCustomerBySectorStackedChart',{attrs:{"chartTitle":'จำนวนลูกค้าสาธารณสุข กลุ่มโรงพยาบาลรัฐ[9]',"customerCategory":9,"officeSectorID":[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg6":"","xl6":"","grow":"","pa-1":""}},[_c('ICTSolutionCustomerPerCustomerBySectorStackedChart',{attrs:{"chartTitle":'จำนวนลูกค้าสถานศึกษา (สพฐ.)[2]',"customerCategory":2,"officeSectorID":[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg6":"","xl6":"","grow":"","pa-1":""}},[_c('ICTSolutionCustomerPerCustomerBySectorStackedChart',{attrs:{"chartTitle":'จำนวนลูกค้าสถานศึกษา สกร.(กศน. เดิม)[3]',"customerCategory":3,"officeSectorID":[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
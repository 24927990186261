<template>
    <v-container>
      <v-layout wrap>
        <v-flex xs12 sm12 md12 lg12 xl12 grow pa-1>
          <CustomerMeetingBySectorChart
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>

      <!--
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <NonICTSolutionCustomerMeetingPerNonICTSolutionCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น'"
            :customerCategory="1"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex> 
  
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <NonICTSolutionCustomerMeetingPerNonICTSolutionCustomerPercentBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น'"
            :customerCategory="1"
            :officeSectorID="[
              '49',
              '50',
              '51',
              '52',
              '53',
              '54',
              '55',
              '56',
              '57',
              '59',
              '62',
              '64',
              '65',
            ]"
          />
        </v-flex>

        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <NonICTSolutionCustomerMeetingPerNonICTSolutionCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม สถานศึกษา'"
            :customerCategory="2"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
  
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <NonICTSolutionCustomerMeetingPerNonICTSolutionCustomerPercentBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม สถานศึกษา'"
            :customerCategory="2"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>

        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <NonICTSolutionCustomerMeetingPerNonICTSolutionCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม ส่วนงานสาธารณสุข'"
            :customerCategory="9"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
  
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <NonICTSolutionCustomerMeetingPerNonICTSolutionCustomerPercentBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม ส่วนงานสาธารณสุข'"
            :customerCategory="9"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>-->
        
      </v-layout>
    </v-container>
  </template>
  
  <script>
  import CustomerMeetingBySectorChart from "./CustomerMeetingBySectorChart.vue";
  //import NonICTSolutionCustomerMeetingPerNonICTSolutionCustomerBySectorStackedChart from "./NonICTSolutionCustomerMeetingPerNonICTSolutionCustomerBySectorStackedChart.vue";
  //import NonICTSolutionCustomerMeetingPerNonICTSolutionCustomerPercentBySectorStackedChart from "./NonICTSolutionCustomerMeetingPerNonICTSolutionCustomerPercentBySectorStackedChart.vue";
  
  
  export default {
    name: "CustomerMeetingDashboard",
    components: {
      CustomerMeetingBySectorChart,
     // NonICTSolutionCustomerMeetingPerNonICTSolutionCustomerBySectorStackedChart,
     // NonICTSolutionCustomerMeetingPerNonICTSolutionCustomerPercentBySectorStackedChart
  
    },
    props: {

    },
  };
  </script>
  

  <style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  </style>
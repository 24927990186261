<template>
  <v-card>
    <div v-if="hasSeries">
      <v-chart class="chart" :autoresize=true :option="option" />
    </div>
  </v-card>
</template>

<script>
import axios from "axios";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent
]);

export default {
  name: "CustomerBySectorStackedChart",
  props: {
    officeSectorID: {
      type: Array,
      required: true,
    },
  },
  components: { VChart },
  data: () => ({
    loaded: false,
    interval: null,
    hasSeries: false,
    series: null,

    option: {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {
        top: "bottom",
      },
      grid: {
        left: "5%",
        right: "5%",
        bottom: "20%",
      },
      title: {
        left: 'center',
        text: '',
      },
      colorBy: "series",
      toolbox: {
        right: 30,
        feature: {
          saveAsImage: { 
            pixelRatio: 3
          }
        }
      },
      xAxis: {
        type: 'category',
        axisLabel: {
          rotate: 45
        }
      },
      yAxis: {
        type: 'value',
      },
      series: []
    },
  }),
  mounted() {
    this.loaded = false;
    this.hasSeries = false;
    this.getDataFromAPI();
    // this.interval = setInterval(() => {
    //   this.getDataFromAPI();
    // }, 60000);
  },
  beforeDestroy() {
    // clearInterval(this.interval);
  },
  methods: {
    getDataFromAPI() {
      const queryParams = {
        params: {
          officeSectorID: this.officeSectorID,
        },
      };
      axios
        .get("/dashboard/ict-solution-customer-by-sector-stacked-bar-chart", queryParams)
        .then((response) => {
          const result = response.data;
          this.option.xAxis.data = result.categories;
          this.option.title.text = `จำนวนลูกค้า ICT Solution`;
          result.series = result.series.map((serie) => {
            serie.type = 'bar';
            serie.stack = 'total';
            // serie.label = {show: true};
            serie.emphasis = {focus: 'series'};
            serie.barWidth = '85%';
            return serie
          })
          // console.log(result.series)
          this.option.series = result.series;
          this.hasSeries = result.series.length > 0;
          this.loaded = true;
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.chart {
  height: 650px;
}
</style>